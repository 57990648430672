@import "./src/assets/styles/variables.scss";
@import "./src/assets/styles/mixins.scss";

@import "./components/login.scss";

.main__loginPage {

  .section_login {
    width: 100%;
    height: 90vh;
    background-color: $primary;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    transition: all 0.3s ease-in-out;
    color: $white;
    padding: 1.5rem;

  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: -25px;

    .button-login {
      background-color: $white;
      color: $primary;

      &:active {
        background-color: $white !important;
        color: $primary;
      }
      &:enabled {
        background-color: $white !important;
        color: $primary;
      }
    }
  }

  .section_register {
    width: 100%;
    height: 6vh;
    transition: all 0.3s ease-in-out;
  }
}

.showRegister {
  height: 90vh !important;
  transition: all 0.3s ease-in-out;
}

.hideLogin {
  height: 6vh !important;
  transition: all 0.3s ease-in-out;
}

.showLoginComponent {
  margin-top: 0;
  transition: all 0.3s ease-in-out;
}

.hideLoginComponent {
  margin-top: -600px;
  transition: all 0.3s ease-in-out;
}