@import "./src/assets/styles/variables.scss";
@import "./src/assets/styles/mixins.scss";

.header_login {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    background-color: $white;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
    h1, h4 {
      margin: 0;
    }
  }

}

.form_login {
  max-width: 350px;
  margin: 0 auto;

  @include tablet {
    margin: 4rem auto 0;
  }
}


.footer_login {
  margin: 1.5rem auto 0;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .p-button-text {
    width: 100%;
    margin-bottom: 1rem;
  }
}