@import "./src/assets/styles/variables.scss";
@import "./src/assets/styles/mixins.scss";

.buttons_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .button_content {
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $white;
  }
}

.button_content_qr {
  height: 1.5rem !important;
  margin-top: 1.8rem;
  .circle {
    border-radius: 50%;
    background-color: $primary !important;
    height: 5rem;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;

    .qr-button {
      background: $white !important;
    }
  }
}



.button_content_perfil {
  border-top-right-radius: 30px;
}

.button_content_frecuentes {
  border-top-left-radius: 30px;
}