@import "./src/assets/styles/mixins";
@import "./src/assets/styles/variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  color: $white
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



//UI

//Components

// ======= Screens =======
// Auth
@import "./src/auth/auth.scss";

// Bifrost
@import "./src/bifrost/pages/BifrostStyles.scss";
