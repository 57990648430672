@import "./src/assets/styles/variables.scss";
@import "./src/assets/styles/mixins.scss";

@import "./src/bifrost/pages/components/FooterStyles.scss";
@import "./src/bifrost/pages/components/HeaderStyles.scss";

.main_container {
  width: 100%;
  height: 100vh;
  background-color: $primary;

  .header_container {
    width: 100%;
    height: 4rem;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  .body_container {
    width: 100%;
    padding: 1rem;

    .tabs_container {
      .p-tabview {
        .p-tabview-nav-container {
          background: transparent !important;
          .p-tabview-nav-content {
            background: transparent !important;
            .p-tabview-nav {
              background: transparent !important;
              li {
                background: transparent !important;

                max-width: 110px;
                @include tablet {
                  max-width: none;
                }
                a {
                  color: $secondary;
                  span {
                    font-size: 12px;
                    @include tablet {
                      font-size: 16px;
                    }
                  }
                }
              }
              li.p-highlight {
                border-bottom: 2px solid $white;
                a {
                  color: $white;
                }
              }
            }
            a {
              background: transparent !important;
            }
          }
        }
        .p-tabview-panels {
          overflow-y: auto;
          max-height: 380px !important;
          background: transparent !important;
          .p-tabview-panel {
            display: grid;
            grid-template-columns: repeat(auto-fit, 120px);
            grid-gap: 1rem;
            @include tablet {
              grid-template-columns: repeat(auto-fit, 250px);
            }
          }

        }
      }
    }


  }

  .footer_container {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 3rem;
  }
}

.content_card {
  display: flex;
  flex-direction: column;
  .header_card {
    width: 100%;

    .home_number {
      @include box-shadow-tridementional;
      width: 60%;
      background: $border;
      padding: .2rem 1rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .body_card {
    background: $white;
    padding: .2rem 1rem;
    height: 80px;
    border-radius: 0 10px 10px 10px;
    @include box-shadow-tridementional;
    .description {
      padding: 1rem 0;
      width: 100%;
      font-size: 13px;
    }
    .condo {
      width: 100%;
      font-size: 13px;
      color: $black_secondary;
      position: relative;

    }
  }
}

.content_sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .menu_sidebar {
    height: 79vh;
  }
  .footer_sidebar {
    height: 8vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px solid $border;
  }
}

