@import "./src/assets/styles/variables.scss";
@import "./src/assets/styles/mixins.scss";

.left {
  width: 20%;
  .menu {
    .button_content {
      .p-button {
        .p-button-icon {
          font-size: 1.3rem !important;
          color: $white;
        }
      }
    }
  }
}

.center {
  width: 60%;
  border-radius: $border-radius;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.right {
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  .content_img {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: $white;
    border-radius: 50%;
    img {
      margin-right: 7px;
    }
  }

  .p-avatar {
    background-color: $secondary;
    color: $white;
  }
}